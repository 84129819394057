import { useEffect } from "react"
import { useAuth0 } from "@auth0/auth0-react"

import NoTemplate from "templates/NoTemplate"

const Login = () => {
  const { loginWithRedirect } = useAuth0()

  useEffect(() => {
    loginWithRedirect()
  }, [])

  return null
}

Login.template = NoTemplate

export default Login
