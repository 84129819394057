import type { Children } from "types"

const NoTemplate = ({ children }: Children) => {
  return (
    <>
      <div className="no-template">{children}</div>
    </>
  )
}

export default NoTemplate
